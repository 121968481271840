import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {MonitoringService} from './monitoring.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  endpoint = environment.monitoringEndpoint;
  positionsRefreshedTime;
  accWithLiqs = [];
  constructor(private http: HttpClient, private monitoringService: MonitoringService) {
    this.fetchPositionsRefreshedTime()
  }

  fetchPositions(name: string) {
    return this.http.get(`${this.endpoint}/positions?name=${name}`,  {headers: this.monitoringService.headers})
  }

  fetchAccounts() {
    return this.http.get(`${this.endpoint}/accs`)
  }
  fetchPositionsRefreshedTime() {
     this.http.get(`${this.endpoint}/positions-refreshed-time`).subscribe(data => {
       this.positionsRefreshedTime = data;
     })
  }
  forcePositionsRefresh() {
     this.http.get(`${this.endpoint}/reload-positions`).subscribe(data => {
       this.fetchPositionsRefreshedTime();
       alert("Refreshing in 20 seconds..")
     })
  }
  accWithLiqLevels() {
    this.http.get(`${this.endpoint}/liq-levels-calculated`).subscribe((data: any) => {
      this.accWithLiqs = data;
    })
  }
}

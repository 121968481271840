import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../services/account.service';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {
  //accounts: any[] = [];
  spotBalances: any = {
    USDT: 0,
    OM: 0,
    BTC: 0,
    ETH: 0,
    USDC: 0
  };
  totalMarginBorrowedOM = 0;
  totalMarginBorrowedUSDT = 0;
  totalMarginCollateralUSDT = 0;
  totalPerpSize = 0;
  totalPerpSizeNotional = 0;
  totalPerpCollateralUSDT = 0;
  totalPerpUnrealizedPNL = 0;
  perpsOnlyFilter: any;
  mainAccOnlyFilter: any;
  nameFilter: any;
  hideSmall: any;
  configureAccounts: any;
  accs: any[] = [];
  //accsWithLiqLevels: any;
  get accounts(): any[] {
    return this.accs.filter(q => q.enabled);
  }
  set accounts(accs) {
    accs.forEach(q => {
      q.enabled = true;
    })
    this.accs = accs;
  }

  get allAccs() {
    return this.accs;
  }

  constructor(public accountService: AccountService) {
  }

  ngOnInit(): void {
    this.accountService.fetchAccounts().subscribe((accounts: any) => {
      this.processAccounts(accounts);
      this.accountService.accWithLiqLevels();
    });

  }

  resetValues() {
    //Perp
    this.totalPerpSize = 0;
    this.totalPerpSizeNotional = 0;
    this.totalPerpUnrealizedPNL = 0;
    this.totalPerpCollateralUSDT = 0;

    //Margin
    this.totalMarginBorrowedUSDT = 0;
    this.totalMarginBorrowedOM = 0;
    this.totalMarginCollateralUSDT = 0;

    this.spotBalances = {
      USDT: 0,
      OM: 0,
      BTC: 0,
      ETH: 0,
      USDC: 0
    };
  }
  processAccounts(accounts?) {
    this.resetValues();
    if (accounts) {
      this.accounts = accounts.map((name: string) => ({name}));
    }

    this.accounts.forEach((account: any) => {
      this.accountService.fetchPositions(account.name).subscribe((data: any) => {
        account.data = data;
        if (data) {
          if (data.papi) {

            let perp = data.positionRisk.filter((q: any) => q.symbol === 'OMUSDT')[0];
            if (perp) {
              this.totalPerpSize += parseFloat(perp.positionAmt);
              this.totalPerpSizeNotional += parseFloat(perp.notional);
              // this.totalPerpCollateralUSDT += perp.initialMargin;
              this.totalPerpUnrealizedPNL += parseFloat(perp.unRealizedProfit || 0);


              account.perpLiquidationLevel = perp.liquidationPrice;
              account.unrealizedPnl = perp.unRealizedProfit;
              account.entryPrice = perp.entryPrice;
              account.perp = perp;

            }

            account.uniMMR = data.account.uniMMR;
            console.log(data);
            if (data.balance) {
              data.balance.filter(q => parseFloat(q.totalWalletBalance) > 0).forEach(balance => {
                if (!this.spotBalances[balance.asset]) {
                  this.spotBalances[balance.asset] = 0;
                }
                this.spotBalances[balance.asset] += parseFloat(balance.totalWalletBalance);
              })
            }
            if (data.spot_balance) {
              Object.keys(data.spot_balance).forEach(balance => {
                if (!this.spotBalances[balance]) {
                  this.spotBalances[balance] = 0;
                }
                this.spotBalances[balance] += parseFloat(data.spot_balance[balance].free);
              })
            }
          } else {
            if (data.balance) {
              Object.keys(data.balance).forEach(key => {
                if (!this.spotBalances[key]) this.spotBalances[key] = 0;
                this.spotBalances[key] += parseFloat(data.balance[key].free);
              })
            }
            if (data.spot_balance) {
              Object.keys(data.spot_balance).forEach(balance => {
                if (!this.spotBalances[balance]) {
                  this.spotBalances[balance] = 0;
                }
                this.spotBalances[balance] += parseFloat(data.spot_balance[balance].free);
              })
            }
            if (data.margin.marginAccount) {
              let borrowedUSDT = data.margin.marginAccount.userAssets.filter((q: any) => q.asset == 'USDT')[0] || 0;
              this.totalMarginBorrowedUSDT += parseFloat(borrowedUSDT.netAsset || 0);
              let borrowedOM = data.margin.marginAccount.userAssets.filter((q: any) => q.asset == 'OM')[0] || 0;
              data.totalBorrowedOm = borrowedOM.netAsset || 0;


              this.totalMarginBorrowedOM += parseFloat(borrowedOM.netAsset || 0);
              this.totalMarginCollateralUSDT += parseFloat(data.margin.marginAccount.totalCollateralValueInUSDT || 0);
            }
            let perp = data.positions.filter((q: any) => q.symbol === 'OM/USDT:USDT')[0];
            if (perp) {
              this.totalPerpSize += parseFloat(perp.contracts);
              this.totalPerpSizeNotional += perp.notional;
              this.totalPerpCollateralUSDT += perp.initialMargin;
              this.totalPerpUnrealizedPNL += parseFloat(perp.unrealizedPnl || 0);

              account.perpLiquidationLevel = perp.liquidationPrice;
              account.unrealizedPnl = perp.unrealizedPnl;

              account.percentage = perp.percentage;
              account.entryPrice = perp.entryPrice;
            }
          }

        }


      });
    });

  }

  refresh() {
    console.log('Reload UI.');
  }

  getBalance(asset = 'USDT', balance: any, attribute = 'totalWalletBalance') {
    if (!balance) return 0;
    if (balance[asset]) {
      return parseInt(balance[asset].free) || 0;
    } else {
      let bal = balance.filter(q => q.asset == asset)[0]

      return bal ? parseInt(bal[attribute]) : 0;
    }

  }

  getTotal(asset: any) {
    let bal = 0;
    this.accounts.forEach(acc => {
      if (acc && acc.data)
      bal += this.getBalance(asset, acc.data.balance);
    })
    return bal;
  }

  sort(colName) {
    this.accounts.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
  }

  toggleAll(b: boolean) {
    this.allAccs.forEach(q => {
      q.enabled = b;
    })
  }

  reProcess(flag) {
    if (!flag) {
      this.processAccounts();
    }
  }
}

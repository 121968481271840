import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {UserService} from "./services/user.service";
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatTableModule} from "@angular/material/table";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { MatIconModule } from '@angular/material/icon';
import { AlertComponent } from './components/alert/alert.component';
import {MatDialogModule} from "@angular/material/dialog";
import { ClaimComponent } from './pages/claim/claim.component';
import {CountdownModule} from "ngx-countdown";
import { HomeComponent } from './pages/home/home.component';
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./app-routing/app-routing.module";
import { AllocationSummaryComponent } from './pages/allocation-summary/allocation-summary.component';
import { OtcStatsComponent } from './pages/otc-stats/otc-stats.component';
import { CodeLoginComponent } from './pages/code-login/code-login.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ChildrenManagementComponent } from './pages/children-management/children-management.component';
import { InvalidLinkComponent } from './pages/invalid-link/invalid-link.component';
import {ClipboardModule} from "ngx-clipboard";
import {ToastrModule} from "ngx-toastr";
import {HttpConfigInterceptor} from "./services/HttpConfigInterceptor";
import { PromptComponent } from './components/prompt/prompt.component';
import { LaunchpoolComponent } from './pages/launchpool/launchpool.component';
import { ShowComponent } from './pages/show/show.component';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import {MatTabsModule} from '@angular/material/tabs';
import { EthAddressPipe } from './pipes/eth-address.pipe';
import { StatsComponent } from './pages/stats/stats.component';
import {MonitoringService} from './services/monitoring.service';
import { MonitoringCompareComponent } from './pages/monitoring-compare/monitoring-compare.component';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FilterPipe} from './pipes/filter.pipe';
import { PositionsComponent } from './pages/positions/positions.component';
import { BinanceClassicAccRowComponent } from './components/binance-classic-acc-row/binance-classic-acc-row.component';
import { BinancePortfolioMarginAccRowComponent } from './components/binance-portfolio-margin-acc-row/binance-portfolio-margin-acc-row.component';
import { GenericFilterPipe } from './pipes/generic-filter.pipe';
import { AccLiqLevelComponent } from './components/acc-liq-level/acc-liq-level.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    ClaimComponent,
    HomeComponent,
    AllocationSummaryComponent,
    OtcStatsComponent,
    CodeLoginComponent,
    SvgIconComponent,
    PageHeaderComponent,
    ChildrenManagementComponent,
    InvalidLinkComponent,
    PromptComponent,
    LaunchpoolComponent,
    ShowComponent,
    MonitoringComponent,
    EthAddressPipe,
    FilterPipe,
    StatsComponent,
    MonitoringCompareComponent,
    PositionsComponent,
    BinanceClassicAccRowComponent,
    BinancePortfolioMarginAccRowComponent,
    GenericFilterPipe,
    AccLiqLevelComponent
  ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatIconModule,
        MatDialogModule,
        CountdownModule,
        RouterModule,
        AppRoutingModule,
        MatSlideToggleModule,
        ClipboardModule,
        ToastrModule.forRoot(),
        MatTabsModule,
        MatListModule,
        MatSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        // ToastrModule added
    ],
  providers: [
    UserService,
    MonitoringService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
  ],
  entryComponents: [
    AlertComponent,
    PromptComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

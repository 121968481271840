<td>{{acc?.name}}</td>
<td>{{acc?.uniMMR | number: '0.0-2'}}%</td>
<td>{{acc?.liquidationPrice | number: '0.2-2'}}</td>
<td>{{acc?.futures_entry_price | number: '0.0-2'}}</td>
<td>{{acc?.pnl | number: '0.0-0'}}</td>
<td>{{acc?.positionValue | number: '0.0-0'}}</td>
<td>{{acc.position_amount | number: '0.0-0'}}</td>
<td>{{acc?.adjustedEquity | number: '0.0-0'}}</td>
<td>{{acc?.initialMargin | number: '0.0-0'}}</td>
<td>{{acc?.maintenance_margin | number: '0.0-0'}}</td>
<td>{{acc?.collateral.OM || 0 | number: '0.0-0'}}</td>
<td>{{acc?.collateral.USDT || 0 | number: '0.0-0'}}</td>
<td>{{acc?.collateral.USDC || 0 | number: '0.0-0'}}</td>
<td>{{acc?.collateral.BTC || 0 | number: '0.0-0'}}</td>
